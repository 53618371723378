import React, { useState, useRef } from "react";
import Icon from "src/components/shared/Icon";
import { terms } from "../../LSI/components/TermsAndConditions/terms";
import SignatureCanvas from "react-signature-canvas";
import { generateTermsAndConditionsDoc } from "../../LSI/pdfTermsGenerator";
import { downloadPDF } from "../../ViewClassroom/components/DataTable/components/GetReactPDF";
import DiversionTermsAndConditionsPDF from "./DiversionTermsAndConditionsPDF";

const CustomizedTermsAndConditions = ({
  user,
  classroom,
  firebase,
  termsAndConditionsAccepted,
  fromDiversion,
}) => {
  const [acceptedTerms, setAcceptedTerms] = useState([
    {
      term: "I have read and I accept all the terms and doncitions",
      accepted: false,
    },
  ]);
  const [open, setOpen] = useState(true);
  const [contractReady, setContractReady] = useState(false);
  const [openFileUrl, setOpenFileUrl] = useState(null);
  const [loadingFileUpload, setLoadingFileUpload] = useState(false);
  const [signed, setSigned] = useState(false);

  const finishSign = async () => {
    await firebase.user(user.uid).update({ termsAndConditionsAccepted: true });
  };

  const downloadDataPdf = async () => {

    setLoadingFileUpload(true);
    let signatureImageBase64 = await signatureRef.current
      .getTrimmedCanvas()
      .toDataURL();

    if (contractReady) {
      downloadPDF({
        document: (
          <DiversionTermsAndConditionsPDF
            terms={classroom.settings.termsAndConditions}
            acceptedTerms={acceptedTerms}
            userName={user.displayName || user.username}
            documentTitle={'RED - Terms and Conditions'}
            base64={signatureImageBase64}
          />
        ),
        fileName: `RED - Terms and Conditions.pdf`,
      })
        .then((res) => {
          setLoadingFileUpload(false);
          console.log("res", res);
        })
        .catch((err) => {
          setLoadingFileUpload(false);
          console.log(err, "err");
        });
      return;
      }

     downloadPDF({
      document: (
        <DiversionTermsAndConditionsPDF
          terms={classroom.settings.termsAndConditions}
          acceptedTerms={acceptedTerms}
          userName={user.displayName || user.username}
          documentTitle={'RED - Terms and Conditions'}
          base64={signatureImageBase64}
        />
      ),
      fileName: `RED - Terms and Conditions.pdf`,
      save: true
    })
      .then(async(res) => {
        setLoadingFileUpload(false);
        console.log("response", res);
        await firebase.userLSITermsAndConditions(user.uid).put(res.blob);
        setContractReady(true);
        setSigned(true);
      })
      .catch((err) => {
        setLoadingFileUpload(false);
        console.log(err, "err");
      });
  };

  const handleSigning = async () => {
    if (contractReady) {
      window.open(openFileUrl, "_blank");
      return;
    }

    setLoadingFileUpload(true);
    let signatureImageBase64 = await signatureRef.current
      .getTrimmedCanvas()
      .toDataURL();

    try {
      const signedDocumentLocation = await generateTermsAndConditionsDoc(
        user.username || user.displayName,
        signatureImageBase64
      );

      console.log("signedDocumentLocation", signedDocumentLocation);
      const signedDocRequest = await fetch(signedDocumentLocation);
      const signedDoc = await signedDocRequest.blob();

      await firebase.userLSITermsAndConditions(user.uid).put(signedDoc);

      setOpenFileUrl(signedDocumentLocation);
      setContractReady(true);
      setSigned(true);
      setLoadingFileUpload(false);
    } catch (error) {
      console.log("termsAndConditions.js 245 | upload error", error);
    }

    console.log("termsAndConditions.js 212 | signature", signatureImageBase64);
  };
  const signatureRef = useRef(null);

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const handleChange = (index) => {
    let acceptedTermsCopy = [...acceptedTerms];
    acceptedTermsCopy[index].accepted = !acceptedTermsCopy[index].accepted;
    setAcceptedTerms(acceptedTermsCopy);
  };

  const disabled = () => {
    return (
      acceptedTerms.some((term) => term.accepted === false) || signed === false
    );
  };

  if (!open) return null;

  return (
    <div className="termsandconditionsmodal__container">
      <div className="termsandconditionsmodal__content">
        <div className="termsandconditionsmodal__title">
          Accept Terms and Conditions
        </div>
        <div className="termsandconditionsmodal__header">
          <div className="header__title">
            My RED Ed, Inc. Diversion Program Terms and Conditions End User
            Agreement ("EULA")
          </div>

          {termsAndConditionsAccepted && user.isAdmin && (
            <div
              className="headerclose__button"
              onClick={() => {
                setOpen(false);
              }}
            >
              X
            </div>
          )}
        </div>
        <div
          className="termsandconditionsmodal__termsbox"
          style={{ height: 300 }}
        >
          {console.log("classroom", classroom)}
          {classroom && classroom.settings.termsAndConditions ? (
            <textarea
              rows={15}
              readonly
              style={{ width: "100%", border: "none" }}
            >
              {classroom.settings.termsAndConditions}
            </textarea>
          ) : (
            terms.map((term, index) => {
              return (
                <div key={index}>
                  {term}
                  <br />
                  <br />
                </div>
              );
            })
          )}
        </div>
        <div className="termsandconditionsmodalterms__container">
          {console.log("acceptedTermsacceptedTerms", acceptedTerms)}
          {acceptedTerms.map((term, index) => {
            return (
              <div
                key={index}
                className="termsandconditionsacceptterms__container"
              >
                <Icon
                  name={
                    term.accepted ? "checkbox-checked" : "checkbox-unchecked"
                  }
                  width={24}
                  height={24}
                  onClick={() => {
                    handleChange(index);
                  }}
                />
                <div className="termsandconditionsterms__text">
                  {term.term}.
                </div>
              </div>
            );
          })}
        </div>
        <div className="termsandconditionsmodal__">
          <div className="signature__container">
            <SignatureCanvas
              penColor="black"
              backgroundColor="rgb(236,236,236)"
              ref={signatureRef}
              canvasProps={{
                width: 454,
                height: 80,
              }}
              onEnd={() => {
                console.log("termsAndConditions.js 206 | signature end");
                setSigned(true);
              }}
            />
          </div>
          <div className="signhere__text">Sign here</div>
          <div className="signhere__text" onClick={clearSignature}>
            Clear Signature
          </div>
        </div>
        <div className="termsandconditionsmodalbutton__container">
          <button
            className="termsandconditionsmodalsubmit__button"
            disabled={disabled()}
            onClick={downloadDataPdf}
          >
            {loadingFileUpload
              ? "Loading..."
              : disabled()
              ? "Complete Fields"
              : contractReady
              ? "Review Contract"
              : "Submit"}
          </button>
          {contractReady && (
            <button
              className="termsandconditionsmodalsubmit__button"
              style={{ marginLeft: 30 }}
              onClick={() => {
                setOpen(false);
                fromDiversion && finishSign();
              }}
            >
              {fromDiversion ? "Next" : "Start Assessment"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomizedTermsAndConditions;
