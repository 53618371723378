import firebase from "firebase/app";

const setClassrooms = (payload) => {
  return {
    type: "classrooms/set",
    payload,
  };
};

const setCurrentClassroom = (payload) => {
  return {
    type: "classroom/set",
    payload,
  };
};

const setCurrentClassroomUsers = (payload) => {
  console.log(
    "classroomsActions.js 16 | setting current classroom usres",
    payload
  );
  return {
    type: "classroomUsers/set",
    payload,
  };
};

const classroomsError = (error) => {
  return { type: "classrooms/error", payload: error };
};

export const getClassroomDataById = (firebase, classId) => {
  return async function (dispatch) {
    console.log("classroomsActions.js 15 | getting class data by id", classId);
    const classDataReq = await firebase.classroom(classId).once("value");
    if (classDataReq.val()) {
      dispatch(setCurrentClassroom(classDataReq.val()));
    } else {
      console.log("EventModal 27 | no class  data");
      dispatch(setCurrentClassroom(null));
    }
  };
};

export const getUsersWithClassId = (firebase, classId) => {
  return async function (dispatch) {
    try {
      const snapshot = await firebase
        .users()
        .orderByChild("classUID")
        .equalTo(classId)
        .once("value");

      const usersObject = snapshot.val();

      const usersList = usersObject
        ? Object.keys(usersObject).map((key) => ({
            ...usersObject[key],
            uid: key,
          }))
        : [];

      console.log(
        "classroomsActions.js 57 | dispatching users list",
        usersList
      );
      dispatch(setCurrentClassroomUsers(usersList));
    } catch (error) {
      console.error("Error fetching users:", error);
      dispatch(setCurrentClassroomUsers([]));
    }
  };
};

export const getClassroomsForAdmin = (authUser) => {
  return async function (dispatch) {
    try {
      const classroomsSnapshot = await firebase
        .database()
        .ref("classrooms")
        .once("value");
      const classrooms = classroomsSnapshot.val();

      dispatch(setClassrooms(classrooms));
    } catch (error) {
      dispatch(classroomsError(error.message));
      console.log("classroomsActions.js 19 | error getting classrooms");
    }
  };
};
