import { getCalendarEvents } from "src/api/googleCalendar";
import { errorNotification, openNotification } from "src/utils/notifications";

export function handleCalendarEvents(calendarId) {
  return async function (dispatch) {
    try {
      let events = [];
      if (!calendarId) {
        dispatch(setCalendarEvents(null));
      } else {
        events = await getCalendarEvents(calendarId);
        dispatch(setCalendarEvents(events.items));
      }
      dispatch(errorGettingCalendarEvents(events.error));
    } catch (error) {
      console.log("calendarEventsActions.js 11 | error", error.message);
    }
  };
}
export const setCurrentCalendarId = (calendarId) => {
  return { type: "currentCalendarId/set", payload: calendarId };
};

export const handleCreateCalendarAndDbEvent = async (
  firebase,
  data,
  usersList,
  currentClassData
) => {
  return async function (dispatch) {
    try {
      let attendeesObject = {};
      usersList.forEach((element) => {
        attendeesObject[element.uid] = {
          attendance: false,
          reminderConfirmed: {
            // uid : {type: phone/mail, confirmed: true/false, token:id}
          },
        };
      });

      const eventObject = {
        title: data.summary,
        createdAt: data.created,
        creator: data.creator,
        attendees: attendeesObject,
        googleCalendarId: data.id,
        classId: currentClassData.className,
        startDate: data.start.dateTime,
        endDate: data.end.dateTime,
        ...(module && { module: module }),
        ...(data.location && { location: data.location }),
        ...(data.description && { description: data.description }),
      };
      // Send the event object after creation
      await firebase.events().push(eventObject);
      openNotification("Success!", "Event created successfully in database!");
      dispatch(handleCalendarEvents(currentClassData.calendarId));
    } catch (error) {
      console.log("EventManager 59 | error creating event", error);
      dispatch(handleCalendarEvents(currentClassData.calendarId));
      errorNotification("Error!", "Error creating event in database!");
    }
  };
};

export const setCalendarEvents = (events) => {
  return { type: "calendarEvents/set", payload: events };
};

export const errorGettingCalendarEvents = (error) => {
  return { type: "calendarEvents/error", payload: error };
};

export const calendarEventsLoading = () => {
  return { type: "calendarEvents/loading" };
};
