import React from "react";

export const Event = ({
  summary,
  day,
  month,
  description,
  startHour,
  endHour,
  attendees,
  authUser,
  event,
}) => {
  if (attendees?.length > 0) {
    //if attendees does not include authUser email, return null
    if (!attendees.includes((attendee) => attendee.email === authUser.email)) {
      return null;
    }
  }
  return (
    <div className="upcomingevents__card__container">
      <div className="upcomingevents__datecontainer">
        <div className="upcomingevents__day">{day}</div>
        <div className="upcomingevents__month">{month?.slice(0, 3)}</div>
      </div>
      <div className="upcomingevents__infocontainer">
        <div className="upcomingevents__name">{summary}</div>
        <div className="upcomingevents__description">{description || event.location}</div>
      </div>
      <div className="upcomingevents__duedatecontainer">{startHour} - {endHour}</div>
    </div>
  );
};
