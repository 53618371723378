import React from "react";
import "../index.css";
import YoutubeEmbed from "./YoutubeEmbed";
import "./index.css";
import InfoCard from "../InfoCard";

const VideoContainer = () => {
  return (
    <div className="landing-box-container">
      <div className="landing-box-left-video">
        <YoutubeEmbed embedId="g8zxfBJDI2A" />
      </div>
      <div className="landing-box-right-video">
        <InfoCard
          headerTitle="Let Us Tell You a Bit About Ourselves"
          title="INTRO VIDEO"
          paragraph="RED is dedicated to keeping people out of the criminal justice
            system — permanently — through programs that enhance the social,
            civic, and financial literacy of individuals referred to court."
          align="right"
        />
      </div>
    </div>
  );
};

export default VideoContainer;
