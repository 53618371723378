import React, { memo, useState, useEffect } from "react";
import "./index.css";

const AdminClassrooms = ({ classrooms, authUser }) => {
  const [filter, setFilter] = useState(null);
  const [favorites, setFavorites] = useState([]);

  // Load favorites and potentially set a filter based on authUser
  useEffect(() => {
    const loadedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    setFavorites(loadedFavorites);
    if (authUser.isAdmin) {
      setFilter(authUser.classId);
    }
  }, [authUser]);

  // Toggle the favorite status of a classroom
  const toggleFavorite = (classId) => {
    const isFavorite = favorites.includes(classId);
    const updatedFavorites = isFavorite
      ? favorites.filter((id) => id !== classId)
      : [...favorites, classId];

    setFavorites(updatedFavorites);
    localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
  };

  // Clear the filter to show all classrooms
  const showAllClassrooms = () => setFilter(null);

  // Access control for non-admin or non-mentor users
  if (!authUser.isAdmin && !authUser.isMentor) return null;

  return (
    <div className="adminclassrooms">
      <h5 style={{ fontWeight: "bold" }}>Classrooms</h5>
      <div className="adminclassrooms__container">
        {classrooms &&
          Object.entries(classrooms)
            .filter(([classId, classInfo]) =>
              filter
                ? classInfo.className === filter || favorites.includes(classId)
                : true
            )
            .map(([classId, classInfo], index) => {
              if (
                authUser.isAdmin ||
                (authUser.isMentor &&
                  classInfo.teachers &&
                  Object.values(classInfo.teachers).some(
                    (teacher) => teacher.email === authUser.email
                  ))
              ) {
                return (
                  <div className="adminclassroom__container" key={index}>
                    {classInfo.className}
                    <a href={`viewClassroom/${classId}`}>View Class</a>
                    <button
                      onClick={() => toggleFavorite(classId)}
                      style={{ marginLeft: "10px" }}
                    >
                      {favorites.includes(classId) ? "★" : "☆"}
                    </button>
                  </div>
                );
              }
              return null;
            })}
        {(authUser.isAdmin || authUser.isMentor) && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#1890ff",
              cursor: "pointer",
            }}
            onClick={showAllClassrooms}
          >
            See all classrooms...
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(AdminClassrooms);
