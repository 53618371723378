import React from "react";
import "./LandingFooter.css";
const LandingFooter = () => {
  return (
    <div className="landingfooter">
      <div className="footercontent">
        <div style={{ position: "relative" }}>
          {/* <img className="red-icon1" alt="" src="/red.svg" />{" "} */}
          <div className="reded__footertitle">RED Ed</div>
          <span className="trademark__symbol">™</span>
        </div>
        <div className="footerinfo">RED Ed ™️ 2024 all rights reserved.</div>
        <div className="footersocialiconscontainer">
          <a className="footer-link" href="" target="_blank">
            <img className="linkedin-icon" alt="" src="/linkedin.svg" />
          </a>
          <a className="footer-link" href="" target="_blank">
            <img className="linkedin-icon" alt="" src="/instagram.svg" />
          </a>
          <a className="footer-link" href="" target="_blank">
            <img className="linkedin-icon" alt="" src="/facebook.svg" />
          </a>
          <a className="footer-link" href="" target="_blank">
            <img className="linkedin-icon" alt="" src="/youtube.svg" />
          </a>
        </div>
        <a href="/privacyPolicy" style={{ color: "white" }}>
          Privacy Policy
        </a>
      </div>
      {/* <div className="footerinfo">{`*$20K reflects post sentencing costs & does not factor prosecutorial costs`}</div> */}
    </div>
  );
};

export default LandingFooter;
