/* eslint-disable */
import React, { Fragment } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

export function ClassStudentsReport({ users, className, documentTitle }) {
  return (
    <Document title={documentTitle} style={styles.documentStyle}>
      <Page size="A4" orientation="portrait">
        <View style={styles.page}>
          <Text style={styles.titleStyle}>{className}</Text>
          <Text style={{ paddingBottom: 16 }}>Student names, emails and phone numbers.</Text>
          {users && users.map((user, index) => (
            <View wrap={false} key={index} style={styles.rowStyle}>
              <Text style={[styles.cellStyle, { fontWeight: index === 0 ? 'bold' : 'normal' }]}>{index === 0 ? "Students" : user.displayName}</Text>
              <Text style={[styles.cellStyle, { fontWeight: index === 0 ? 'bold' : 'normal' }]}>{index === 0 ? "Email" : user.email}</Text>
              <Text style={[styles.cellStyle, { fontWeight: index === 0 ? 'bold' : 'normal' }]}>{index === 0 ? "Phone" : user.phoneNumber}</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  documentStyle: {
    fontFamily: "Poppins",
    backgroundColor: "#ffffff",
    color: "#000000",
  },
  titleStyle: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 18,
  },
  rowStyle: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 2,
    width: "100%",
  },
  cellStyle: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: '#CECECE',
    borderBottomStyle: 'solid',
    padding: 10,
    fontSize: 8,
  },
  page: {
    backgroundColor: "#ffffff",
    fontFamily: "Poppins",
    fontSize: 10,
    padding: "30px 40px",
    color: "#000000",
  },
  pdf_page_title: {
    fontWeight: "bold",
    fontSize: 18,
    fontFamily: "Poppins",
  },
  pdf_page_subtitle: {
    fontWeight: "bold",
    fontSize: 14,
  },
  pdf_normal_space: {
    marginTop: 10,
  },
  pdf_big_space: {
    marginTop: 20,
  },
  pdf_bold: {
    fontWeight: "bold",
    color: "#000000",
  },
  redTitle: {
    fontSize: 14,
    color: "#FFFFFF",
  },
  redContainer: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#d94645",
    width: 220,
    height: 40,
    padding: 10,
    fontFamily: "Poppins",
  },
});