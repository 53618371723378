/* eslint-disable */
import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { generateUniqueNumber } from "src/components/Settings";

// This component generates a sleek transcript for a user in a classroom based on the user's data
// Information on the transcript includes:
// 1. User's name
// 2. User's email
// 3. User's date of birth
// 4. User ID
// 5. Lesson modules
// 6. Quiz scores
// 7. Issue date
// 8. Signature of the program coordinator
// 9. Name of organization at the top of the page (e.g. "RED Ed.")

const gradeBelongsToScore = (score) => {
  if (score >= 90) return "A";
  if (score >= 80) return "B";
  if (score >= 70) return "C";
  if (score >= 60) return "D";
  return "F";
};

function UserTranscriptReport({ userData }) {
  return (
    <Document title={"User Transcript"} style={styles.documentStyle}>
      <Page size="A4" orientation="portrait" style={styles.page}>
        {/* Organization Name */}
        <Text style={styles.transcriptTitle}>Student Transcript</Text>
        <Text style={styles.organizationName}>RED Ed.</Text>
        {/* Transcript Title */}
        {/* User Information */}
        <View style={styles.userInfoSection}>
          <Text style={styles.userInfo}>{userData?.displayName}</Text>
          <Text style={styles.userInfo}>
            <Text style={styles.label}>Email: </Text>
            {userData?.email}
          </Text>
          {/* <Text style={styles.userInfo}>
            <Text style={styles.label}>Date of Birth: </Text>
            {userData?.dateOfBirth}
          </Text> */}
          <Text style={styles.userInfo}>
            <Text style={styles.label}>Student ID: </Text>
            {generateUniqueNumber(userData?.uid)}
          </Text>
        </View>
        {/* Lesson Modules and Quiz Scores */}
        <View style={styles.modulesSection}>
          <Text style={styles.sectionTitle}>Academic Performance</Text>
          {Object.values(userData.lessons).map((module, index) => {
            let scores = [];
            let averageScore = 0;
            Object.values(module.sections).forEach((section) => {
              let score = section?.quizPerformance; // this is a percentage string (e.g "0.90"), representing a percentage score of 90%
              if (!score) return;
              scores.push(parseFloat(score) * 100);
            });
            averageScore = scores.reduce((a, b) => a + b, 0) / scores.length;
            return (
              <View key={index} style={styles.module}>
                <Text style={styles.moduleName}>{module.name}</Text>
                <Text style={styles.moduleScore}>
                  Score: {gradeBelongsToScore(averageScore)} (
                  {averageScore.toFixed(1)}%)
                </Text>
              </View>
            );
          })}
        </View>
        <View style={styles.footer}>
          <Text style={styles.issueDate}>
            Issued on: {new Date().toLocaleDateString()}
          </Text>
          <Text style={styles.signatureLine}>Zeus Luby & David Windecher</Text>
          <Text style={styles.signatureLabel}>Program Coordinators</Text>
        </View>

        <View style={{ display: "flex", justifyContent: "center" }}>
          <Image
            src="https://firebasestorage.googleapis.com/v0/b/red-platform.appspot.com/o/images%2Fregistrar-seal.png?alt=media&token=0027cbf0-d712-4492-b19e-e33d18806fb7"
            style={{ width: "100%", ...styles.seal }}
          />
        </View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  seal: {
    // position: "absolute",
    bottom: 20,
    // left: -20,
    margin: "0px auto",
    width: 200,
    height: 200,
  },
  page: {
    padding: 20,
    fontFamily: "Helvetica",
    lineHeight: 1.6,
    marginLeft: 8,
  },
  organizationName: {
    fontSize: 24,
    textAlign: "left",
    marginLeft: 8,
    // marginBottom: 16,
    fontWeight: "bold",
    color: "#2E3A59",
  },
  transcriptTitle: {
    fontSize: 14,
    textAlign: "left",
    marginLeft: 8,
    // marginBottom: 20,
    fontWeight: "bold",
    color: "#2E3A59",
  },
  userInfoSection: {
    marginBottom: 30,
    paddingHorizontal: 10,
  },
  userInfo: {
    fontSize: 12,
    marginBottom: 8,
    color: "#4A4A4A",
    fontWeight: "extrabold",
  },
  label: {
    fontWeight: "bold",
    color: "#2E3A59",
  },
  modulesSection: {
    marginBottom: 10,
    paddingHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 16,
    marginBottom: 12,
    fontWeight: "bold",
    color: "#2E3A59",
    borderBottom: "2px solid #E0E0E0",
    paddingBottom: 8,
  },
  module: {
    marginBottom: 8,
  },
  moduleName: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E3A59",
  },
  moduleScore: {
    fontSize: 10,
    color: "#4A4A4A",
  },
  footer: {
    marginTop: 20,
    textAlign: "center",
  },
  issueDate: {
    fontSize: 12,
    marginBottom: 12,
    color: "#4A4A4A",
  },
  signatureLine: {
    fontSize: 12,
    marginBottom: 5,
    color: "#2E3A59",
  },
  signatureLabel: {
    fontSize: 12,
    color: "#4A4A4A",
  },
});

export default UserTranscriptReport;
