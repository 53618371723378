import React, { useEffect } from "react";

import { Spinner } from "src/components/Spinner";
import FinalAssessment from "./components/FinalAssessment";
import FinishedCourse from "./components/FinishedCourse";
import InitialAssessments from "./components/InitialAssessments";
import Modules from "./components/Modules";

import "./index.css";
import EQAssessmentCard from "./components/InitialAssessments/EQAssessmentCard";
import AssessmentLinkCard from "./components/AssessmentLinkCard";

import AndroidQR from "src/android-qr.jpeg";
import IosQR from "src/ios-qr.jpeg";
import {
  initialLSIShown,
  checkAllModulesComplete,
  finalLSIActive,
  initialEqShown,
  exitEqShown,
  finalLSIAssessmentShown,
  finishedInitialAssessments,
  finalAssessmentShown,
  finalAssessmentComplete,
} from "./unlockUtils";
import MyLoader from "src/utils/placeholders";

export const CourseContent = ({
  firebase,
  authUser,
  getClassroomData,
  classData,
  classDataLoading,
  modules,
  handleModuleStart,
}) => {
  useEffect(() => {}, [
    authUser?.classUID,
    authUser?.lessons,
    authUser?.allModulesComplete,
  ]);

  if (classDataLoading && classData === null) {
    return (
      <div className="coursecontent__container">
        <MyLoader />
      </div>
    );
  }

  return (
    <div className="coursecontent__container">
      <InitialAssessments
        getClassData={getClassroomData}
        authUser={authUser}
        classData={classData}
        finishedInitialAssessments={finishedInitialAssessments(
          authUser,
          classData
        )}
      />

      <div className="preassessmentlinks__container">
        <AssessmentLinkCard
          title="LSI Assessment"
          description="Comprehensive assessment to help you achieve your goals and improve the quality of your life."
          shown={initialLSIShown(authUser, classData)}
          active={true}
          destinationRoute="/lsiassessment"
          timeToComplete="35min"
        />
        <AssessmentLinkCard
          title="EQ Assessment"
          description="Learn about your emotional intelligence with our comprehensive Ghyst EI assessment."
          shown={initialEqShown(authUser, classData)}
          active={!initialLSIShown(authUser, classData)}
          destinationRoute="/eqassessment"
          timeToComplete="15min"
        />
      </div>

      <Modules
        firebase={firebase}
        authUser={authUser}
        finalAssessment={finalAssessmentShown(authUser, classData)}
        finishedInitialAssessments={finishedInitialAssessments(
          authUser,
          classData
        )}
        classData={classData}
        modules={modules}
        handleModuleStart={handleModuleStart}
      />

      {authUser?.classId &&
        authUser?.eqTestComplete &&
        authUser?.allModulesComplete && (
          <EQAssessmentCard
            available={classData?.settings?.eqAvailable ? true : false}
            enabled={false}
            done={false}
            isAdmin={authUser?.isAdmin}
            isMentor={authUser?.isMentor}
          />
        )}

      <FinalAssessment
        finalAssessment={finalAssessmentShown(authUser, classData)}
        finalAssessmentDone={finalAssessmentComplete(authUser, classData)}
        authUser={authUser}
        enabled={checkAllModulesComplete(authUser, classData)}
      />
      <div className="preassessmentlinks__container">
        <AssessmentLinkCard
          title="EQ Exit Assessment"
          description="Learn about your emotional intelligence with our comprehensive Ghyst EI assessment."
          shown={exitEqShown(authUser, classData)}
          active={checkAllModulesComplete(authUser, classData)}
          destinationRoute="/eqassessment"
          timeToComplete="15min"
        />
        <AssessmentLinkCard
          title="LSI Final Assessment"
          description="Comprehensive assessment to help you achieve your goals and improve the quality of your life."
          shown={finalLSIAssessmentShown(authUser, classData)}
          active={finalLSIActive(authUser, classData)}
          destinationRoute="/lsiassessment"
          timeToComplete="30min"
        />
      </div>
      <div>
        <div
          style={{
            backgroundColor: "rgba(217, 70, 69, 0.2)",
            padding: 20,
            borderRadius: 20,
            margin: 20,
            maxWidth: 600,
            justifyContent: "space-between",
          }}
          className="qrcodes__contentcontainer"
        >
          <div style={{ textAlign: "center", fontSize: 24, marginBottom: 20 }}>
            Scan the QR Codes and download the app!
          </div>
          <div
            style={{
              display: "flex",
              // flexDirection: "row",
              justifyContent: "space-evenly",
            }}
            className="qrcodes__container"
          >
            <div style={{ textAlign: "center" }}>
              <img style={{ width: 200, height: 200 }} src={AndroidQR} />
              <div style={{ fontWeight: "bold", fontSize: 20, marginTop: 20 }}>
                Android
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <img style={{ width: 200, height: 200 }} src={IosQR} />
              <div style={{ fontWeight: "bold", fontSize: 20, marginTop: 20 }}>
                Iphone
              </div>
            </div>
          </div>
        </div>
      </div>
      <FinishedCourse
        isAdmin={authUser?.isAdmin}
        allModulesComplete={authUser?.allModulesComplete}
        finalAssessmentComplete={finalAssessmentComplete(authUser, classData)}
        classData={classData}
      />
    </div>
  );
};

export default CourseContent;
