import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthUserContext } from "../../Session";
import Spin from "antd/es/spin";
import "../index.css";
import CustomizedTermsAndConditions from "./customizedTermsAndConditions";

export const JoinedClassModal = ({ firebase }) => {
  const [loader, setLoader] = useState(false);
  const [step, setStep] = useState(2);
  const [classroom, setClassroom] = useState();
  const authUser = useContext(AuthUserContext);
  const history = useHistory();
  let classSelectedOnLogin = localStorage.getItem("courtName");

  useEffect(() => {
    setLoader(true);
    firebase
      .classrooms()
      .orderByChild("className")
      .equalTo(classSelectedOnLogin)
      .once("value")
      .then((classrooms) => {
        let classroom = classrooms.val();
        console.log("JoinClass.js 48 | got all classrooms", classroom);
        if (classroom) {
          console.log("JoinClass.js 52 | classroom exists");
          let classroomToJoin = Object.entries(classroom)[0];
          setClassroom(classroomToJoin[1]);
          if (classroomToJoin && !authUser.classId) {
            const { questId, calendarId } = classroomToJoin[1];
            firebase
              .user(authUser.uid)
              .update({
                classId: classSelectedOnLogin,
                classUID: classroomToJoin[0],
                calendarId,
                questClassId: questId ? questId : null,
                userPaidForDiversion: false,
                introVideoDone: true,
              })
              .then(() => {
                // window.location.reload();
                setLoader(false);
                console.log("JoinClass.js 67 | joined classroom!");
              });
          } else {
            setLoader(false);
          }
        } else {
          console.log("JoinClass.js 54 | no classroom");
        }
      });
  }, []);

  const handleNext = () => {
    if (step === 2) {
      localStorage.removeItem("courtName");
      history.push("/home");
    } else {
      setStep((prevState) => prevState + 1);
    }
    // if(events && !authUser.hasCalendarAttachedToGoogle){
    //   firebase.user(authUser.uid).update({
    //     hasCalendarAttachedToGoogle: true,
    //   });
    // }
  };

  console.log("classroom", classroom);

  return (
    <div className="preAdjudication__modal">
      <div className="preAdjudication__modal__container">
        {loader ? (
          <Spin />
        ) : step === 1 ? (
          <div>
            <div className="preAdjudication__modal__header">Welcome!</div>
            <div>
              <div style={{ fontWeight: "bold", fontSize: 28 }}>
                {" "}
                Welcome to class "Courtroom 1"{" "}
              </div>
              <div style={{ fontSize: 20 }}>
                {" "}
                First we need to do some necessary procedures and then we can
                start your journey of rehabilitation, ready?{" "}
              </div>
            </div>
          </div>
        ) : step === 2 ? (
          <CustomizedTermsAndConditions
            user={authUser}
            firebase={firebase}
            classroom={classroom}
            navigate={history}
            termsAndConditionsAccepted={authUser.termsAndConditionsAccepted}
            fromDiversion={handleNext}
          />
        ) : (
          <div style={{ fontWeight: "bold" }}>Payment page</div>
        )}
        <div className="preAdjudicationButton__container">
          <button onClick={handleNext}>Next</button>
        </div>
      </div>
    </div>
  );
};
