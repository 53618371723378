import React from "react";
import Icon from "../../../shared/Icon";
import WorkInProgress from "../../../shared/Tooltip";

import "./index.css";

const LearnMoreContainer = () => {
  return (
    <div className="learnmore__container">
      <div className="learnmore__left">
        <div className="learnmore__title">Want to Learn More About Recidivism?</div>
        <p className="learnmore__subtitle">Go to the link to learn more about our organization</p>
      </div>
      <div className="learnmore__right">
        <a
          href="https://www.stoprecidivism.org"
          target="_blank"
          className="learnmore__button"
        >
          Learn More
        </a>
      </div>
    </div>
  );
};

export default LearnMoreContainer;
