export const setLessonLoading = () => {
  return {
    type: "lesson/setLoading",
  };
};

export const getSectionsByLessonId = (firebase, lessonId) => {
  return async function (dispatch) {
    console.log("lessonActions.js 13 | getting data from dispatch");
    try {
      const lessonSectionsById = await firebase
        .sections(lessonId)
        .once("value");

      if (lessonSectionsById.val()) {
        dispatch(setLessonSections(lessonSectionsById.val()));
      } else {
        console.log("lessonActions.js 13 | no values");
      }
    } catch (error) {
      console.log("lessonActions.js 25 | error", error);
    }
  };
};

export const setLessonSections = (lessonSections) => {
  const sectionsObject = lessonSections.sections;
  const sectionsListFromDb = sectionsObject
    ? Object.keys(sectionsObject).map((key, index) => ({
        ...sectionsObject[key],
        uid: key,
        index: index,
      }))
    : null;

  return {
    type: "lesson/setSections",
    payload: lessonSections,
  };
};
export const setLessonSectionsError = (error) => {
  return {
    type: "lesson/setLessonSectionsError",
    payload: error,
  };
};
