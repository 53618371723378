import React from "react";
import Icon from "../../../shared/Icon";

import "./index.css";

const curriculum = [
  { icon: "user", name: "Orientation, Personal Assessments" },
  { icon: "record-restriction", name: "Mentor Intros, GED Enrollment" },
  { icon: "mentor-assignments", name: "Emotional Intelligence I" },
  { icon: "know-your-rights", name: "Emotional Intelligence II" },
  { icon: "your-voice-matters", name: "Know Your Rights" },
  { icon: "anger-management", name: "Your Voice Matters" },
  {
    icon: "emotional-intelligence",
    name: "Substance Use Disorder & Addiction",
  },
  { icon: "substance-abuse", name: "Health And Fitness" },
  { icon: "financial-education", name: "Finance Fundamentals" },
  { icon: "employee-training", name: "Entrepreneurship" },
  {
    icon: "employee-training",
    name: "Career Readiness, Placement, & Inclusion",
  },
  { icon: "record-restriction", name: "Record Restriction & Graduation" },
];

const CurriculumContainer = () => {
  return (
    <div className="landing-box-curriculum-container">
      <h3 className="landing-curriculum-header">Curriculum</h3>
      <p className="curriculum-subheader-text">
        RED Ed's online Restorative Justice Curriculum is an innovative 12-month
        program that is revolutionizing the criminal justice system by creating
        a sustainable strategy for those seeking lasting rehabilitation. By
        empowering individuals with targeted educational programming and
        invaluable life skills training, RED Ed is creating safer communities while
        shrinking the tax burden on each and every taxpayer.
      </p>
      <div className="curriculum-cards-container">
        {curriculum.map(({ name, icon }, index) => {
          return (
            <CurriculumCard
              key={index}
              name={name}
              icon={icon}
              month={index + 1}
            />
          );
        })}
      </div>
    </div>
  );
};

const CurriculumCard = ({ name, icon, month }) => {
  return (
    <div className="curriculum-card">
      <Icon name={icon} />
      <p className="curriculum-card-name">{name}</p>
      <p className="curriculumcard__month">Month {month}/12</p>
    </div>
  );
};

export default CurriculumContainer;
