import React, { useContext } from "react";
import { EventModal } from "src/components/shared/EventModal";

import { AuthUserContext } from "../../Session";

import "../index.css";

export const AdminEvents = ({ token, calendarId, getCalendar,classId }) => {
  const authUser = useContext(AuthUserContext);
  if (!authUser.isAdmin) return null;
  return (
    <div className="calendar-admin-container">
      <h3>Admin Calendar</h3>
      <EventModal calendarId={calendarId} classId={classId} />

      <hr />
    </div>
  );
};
