/* eslint-disable no-unused-vars */
import React, { Component, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthUserContext } from "../Session";
import * as ROLES from "../../constants/roles";

import "./index.css";

import SideNavigation from "./components/SidebarNavigation";
import TopNavigation from "./components/TopNavigation";

import { useDispatch } from "react-redux";
import { toggleSignupModal } from "../../store/authUser/authUserActions";

const Navigation = () => {
  const authUser = useContext(AuthUserContext);
  const dispatch = useDispatch();
  return (
    <>
      {authUser ? (
        <div style={{ display: "inline-block", width: "100%" }}>
          <SideNavigation authUser={authUser} />
          <TopNavigation authUser={authUser} />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <NavigationNonAuth dispatch={dispatch} />
        </div>
      )}
    </>
  );
};

// TODO refactor into separate files
class NavigationNonAuth extends Component {
  state = {
    collapsed: true,
  };

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const collapsed = this.state.collapsed;
    const classOne = collapsed
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse show";
    const classTwo = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-right";
    return (
      <nav className="navbar navbar-expand-lg navbar-dark transparent-nav navbarmain__container">
        <div className="navbar__container">
          <Link className="navtitle" to="/">
            RED Ed{" "}
            <span
              style={{
                fontWeight: "normal",
                fontSize: 16,
                position: "absolute",
                marginLeft: 5,
              }}
            >
              ™
            </span>
          </Link>
          <button
            onClick={this.toggleNavbar}
            className={`${classTwo}`}
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img src={"/assets/images/hamburger-navbar.svg"} alt="navbar" />
          </button>
          <div className={`${classOne}`} id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <div
                  onClick={() => {
                    this.props.dispatch(toggleSignupModal(true));
                  }}
                >
                  <div className="nav-link navigation__signuplink">Sign Up</div>
                </div>
              </li>
              {/* <li className="nav-item active navigation__signin"> */}
              <div
                onClick={() => {
                  this.props.dispatch(toggleSignupModal(true));
                }}
              >
                <div
                  className="nav-link navigation__signinlink navigation__signin"
                  //to="/signin"
                >
                  Sign In
                </div>
              </div>
              {/* </li> */}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
