import React from "react";
import Icon from "../shared/Icon";
import WorkInProgress from "../shared/Tooltip";
import "./index.css";

const LandingFooter = () => {
  return (
    <>
    <div className="landingfooter__container">
      <div className="landingfooter__social">
        <div className="landingfooter__logo">RED Ed</div>
        <div className="landingfooter__info">
         RED Ed ™️ 2024 all rights reserved.
        </div>
        <div className="landingfooter__sociallinks">
          <a
            href="https://www.linkedin.com/company/rehabilitation-enables-dreams"
            target="_blank"
            className="social__link"
          >
            <Icon name="linkedin" />
          </a>
          <a
            href="https://www.instagram.com/stop_recidivism"
            target="_blank"
            className="social__link"
          >
            <Icon name="instagram" />
          </a>
          <a
            href="https://www.facebook.com/stoprecidivism"
            className="social__link"
          >
            <Icon name="facebook" />
          </a>
          <a
            href="https://www.youtube.com/watch?v=g8zxfBJDI2A&ab_channel=RehabilitationEnablesDreams"
            target="_blank"
            className="social__link"
          >
            <Icon name="youtube" />
          </a>
          {/* <div className="social__link">
            <Icon name="twitter" />
          </div> */}
        </div>
      </div>
      {/* <div className="landingfooter__navigation">
        <div className="landingfooter__menutitle">Menu</div>
        <div className="menutext__container">
          <div className="landingfooter__menutext">About</div>
          <div className="landingfooter__menutext">myRED</div>
          <div className="landingfooter__menutext">Features</div>
          <div className="landingfooter__menutext">Contact Us</div>
        </div>
      </div> */}
      {/* <div className="landingfooter__subscribecontainer">
        <div className="landingfooter__title">Subscribe to our Newsletter!</div>
        <div className="landingfooter__subtitle">
          Get the latest updates from the RED team!
        </div>
        <div className="landingfooter__subscribe">
          <div className="subscribe__contact">
            <Icon width={23} height={18} />{" "}
            <input
              className="subscribe__input"
              type="text"
              placeholder="Enter email here"
            />
          </div>
          <WorkInProgress>
            <button className="subscribe__now">Subscribe Now</button>
          </WorkInProgress>
        </div>
      </div> */}
    </div>
    {/* <div style={{color:'white',marginTop:-60,zIndex:100}}>*$20K reflects post sentencing costs & does not factor prosecutorial costs</div> */}

    </>
  );
};

export default LandingFooter;
