const initialState = {
  lessonLoading: false,
  finishLoading: false,
  lessonSections: null,
  sections: null,
  currentSection: null,
  mode: "view",
  loadedSections: false,
  name: null,
  description: null,
  type: null,
  imageUrl: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "lesson/setLoading":
      return { ...state, lessonLoading: true };
    case "lesson/setSections":
      return { ...state, lessonLections: payload };

    default:
      return state;
  }
};
