import React, { useState, useEffect } from "react";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import { useSortBy, useTable } from "react-table";

const ModuleReviews = ({ firebase }) => {
  const [modules, setModules] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [moduleReviewsName, setModuleReviewsName] = useState(null);

  useEffect(() => {
    getReviews();
  }, []);

  const getReviews = async () => {
    const reviewsRequest = await firebase.moduleReviews().once("value");
    const modulesInfoRequest = await firebase
      .getModulesOverview()
      .once("value");
    const moduleData = reviewsRequest.val();
    const modulesOverview = modulesInfoRequest.val();

    if (moduleData && modulesOverview) {
      const moduleIds = Object.keys(moduleData);

      const moduleReviews = moduleIds.map((moduleId) => {
        const reviews = Object.values(moduleData[moduleId]);
        const ratings = reviews.map((review) => review.rating);
        const name = modulesOverview[moduleId]?.name;
        const averageRating =
          ratings.reduce((acc, curr) => acc + curr, 0) / ratings.length;

        return {
          moduleId,
          averageRating,
          name,
          reviews,
        };
      });

      setModules(moduleReviews);
    } else {
      console.log("No data available");
    }
  };

  const data = React.useMemo(
    () =>
      modules.map((module) => {
        return {
          name: module.name,
          averageRating: module.averageRating.toFixed(1),
          totalReviews: module.reviews.length,
          reviews: module.reviews,
        };
      }),
    [modules]
  );

  const columns = React.useMemo(
    () => [
      { Header: "Module Name", accessor: "name" },
      { Header: "Average Rating", accessor: "averageRating" },
      { Header: "Total Reviews", accessor: "totalReviews" },
    ],
    []
  );

  const tableInstance = useTable({ columns, data }, useSortBy);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div style={{ paddingLeft: 90 }}>
      * Click any module title to see review text shown below
      <h1>Module Ratings</h1>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      onClick={() => {
                        console.log("Reviews 101 | row", row);
                        const { name, reviews } = row.original;
                        setReviews(reviews);
                        setModuleReviewsName(name);
                      }}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      {moduleReviewsName && moduleReviewsName}
      {reviews &&
        reviews.map((review, index) => {
          console.log("Reviews | 72", review);
          return <div key={index}>{review.textFeedback}</div>;
        })}
    </div>
  );
};

export default compose(withFirebase)(ModuleReviews);
