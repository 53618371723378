import React, { useState } from "react";
import { API_URL } from "src/api/apiIUrl";

// userPaidForDiversion is changed to true after the user completes the Stripe payment

export function StripePaymentLinkButton({ userId }) {
  const [loadingUrl, setLoadingUrl] = useState(false);
  const generatePaymentLinkAndRedirectToStripe = async () => {
    console.log('API_URL', API_URL)
    setLoadingUrl(true);
    try {
      const requestPaymentLink = await fetch(`${API_URL}/paymentStripe`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("StripePaymentButton 19 | requestPaymentLink", requestPaymentLink);
      const data = await requestPaymentLink.json();
      console.log("dataaa", data);
      setLoadingUrl(false);
      window.open(
        `${data.paymentLink.url}?client_reference_id=${userId}`,
        "_blank"
      );
    } catch (error) {
      console.log("StripePaymentButton 19 | error", error);
    }
  };

  return (
    <div>
      <button
        className="button12"
        onClick={generatePaymentLinkAndRedirectToStripe}
      >
        <div className="pay-100">{!loadingUrl ? "Pay $100" : "Loading..."}</div>
      </button>
    </div>
  );
}
