// redux calendar events reducer
const initialState = {
  items: null,
  currentCalendarId: null,
  currentClassId: null,
  currentEventId: null,
};

export const calendarEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "calendarEvents/set":
      return { ...state, items: action.payload };
    case "currentCalendarId/set":
      return { ...state, currentCalendarId: action.payload };
    case "currentClassId/set":
      return { ...state, currentClassId: action.payload };
    default:
      return state;
  }
};
