import React, { useMemo, useEffect, useState, useContext } from "react";
import "../index.css";
import { useTable, useSortBy } from "react-table";
import Button from "src/components/shared/Button";
import {
  AuthUserContext,
  withAuthentication,
  withAuthorization,
} from "../../Session";
import { mockData } from "../mockData";
import * as ROLES from "../../../constants/roles";
import { getCalendarEvent } from "src/api/googleCalendar";
import TestEventReminderModal from "./TestEventReminderModal";
import { withFirebase } from "../../Firebase";
import { compose } from "recompose";
import EventReminderModal from "./EventReminderModal";
import { sendOneEmail } from "src/api/emails";
import { sendTextMessage } from "src/api/texts";
import moment from "moment";
import { toast } from "react-toastify";
import EditEventModal from "./EditEventModal";
import ToggleSwitch from "src/components/shared/ToggleSwitch";
import { ClassroomChat } from "src/components/ChatBubble";
import {
  matchUserProperty,
  checkReminderConfirmed,
  checkStudentProgress,
} from "../eventManagerUtils";
import EventHeader from "./EventHeader";
import EventDetails from "./EventDetails";
import AttendanceTable from "./AttendanceTable";

export const AttendancePage = ({ firebase }) => {
  let [loadingEvent, setLoadingEvent] = useState(false);
  let [loadingUsers, setLoadingUsers] = useState(false);
  let [users, setUsersList] = useState([]);
  const authUser = useContext(AuthUserContext);
  const [databaseEvent, setDatabaseEvent] = useState();
  const [classroom, setClassroom] = useState({});
  const [hasNoEventObject, setHasNoEventObject] = useState(false);
  const [attendanceMode, setAttendanceMode] = useState(false);
  const [editEventOpen, setEditEventOpen] = useState(false);
  const [testReminderOpen, setTestReminderOpen] = useState(false);
  const [moduleName, setModuleName] = useState();
  let [eventData, setEventData] = useState({
    attendeesList: [],
  });
  let [reminderModalOpen, setReminderModalOpen] = useState({});

  const splittedUrl = window.location.href.split("/");
  const calendarId = splittedUrl[splittedUrl.length - 1];
  const isPastEvent =
    eventData.endDate && moment(eventData.endDate).diff(moment(), "days") < 0;

  useEffect(() => {
    console.log("useeffects");
    setLoadingEvent(true);
    firebase
      .events()
      .orderByChild("googleCalendarId")
      .equalTo(calendarId)
      .once("value")
      .then(async (snapshot) => {
        const response = snapshot.val();
        if (response) {
          const databaseEvent = Object.values(snapshot.val())[0];
          setDatabaseEvent(databaseEvent);
          const classroom = await getClassroomByClassName(
            databaseEvent.classId
          );
          setClassroom(classroom);
          fetchEventData(classroom.calendarId);
        }
      })
      .catch((error) => {
        console.log("errorrrrrr", error);
      });
  }, []);

  const getClassroomByClassName = async (className) => {
    let classroom;
    await firebase
      .classrooms()
      .orderByChild("className")
      .equalTo(className)
      .once("value")
      .then(async (classrooms) => {
        const response = classrooms.val();
        console.log("response", response);
        if (response) {
          classroom = Object.values(classrooms.val())[0];
          classroom.uid = Object.keys(classrooms.val())[0];
          console.log(
            "AttendancePage.js 93 | classroom",
            Object.keys(classrooms.val())[0]
          );
          console.log("if response", classroom);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });

    return classroom;
  };

  // filtramos por la gente que tiene displayname
  let totalRemindersConfirmed = 0;
  let totalUsersAttendance = 0;

  const handleTokenCreation = async (format, uid, isReminder) => {
    // we create the token in the tokens collection
    // if the second argument is passed, is a bulk token creation
    const token = await firebase.tokens().push({
      eventId: eventData.eventId,
      attendeeId: uid ? uid : reminderModalOpen.uid,
    });
    // then we update the users info in the event with the token data to link them together
    let path = `/attendees/${
      uid ? uid : reminderModalOpen.uid
    }/reminderConfirmed/${token.path.pieces_[1]}`;
    await firebase.getEventData(eventData.eventId).update({
      [path]: {
        format: isReminder ? format : "other",
        confirmed: false,
      },
    });
    return token.path.pieces_[1];
  };

  const handleSendOneMail = async (
    to,
    subject,
    message,
    emailRecipientName,
    uid,
    fromBulk = false
  ) => {
    if (!to) {
      toast.error("This user has no mail");
    } else {
      /// We create the token
      const isReminder = message.includes(
        "{https://myreded.com/event-confirmation/token}"
      );
      const token = await handleTokenCreation("mail", uid, isReminder);
      const link =
        process.env.NODE_ENV === "development"
          ? `http://localhost:3000/event-confirmation/${token}/${authUser.calendarId}`
          : `https://myreded.com/event-confirmation/${token}/${authUser.calendarId}`;
      let finalMessage = message.replace(
        "{https://myreded.com/event-confirmation/token}",
        link
      );

      sendOneEmail(to, subject, finalMessage, emailRecipientName)
        .then((res) => {
          console.log("res mail", res);
          if (!fromBulk) {
            toast.success(`Mail sent succesfully`);
          }
        })
        .catch((err) => {
          toast.error(
            `Hubo un error al enviarle el correo a ${emailRecipientName}`
          );
        });
      setReminderModalOpen(false);
    }
  };

  const handleSendOneSMS = async (to, message, uid, fromBulk = false) => {
    // uid is only in case of bulk message
    if (!to) {
      toast.error("This user has no phone");
    } else {
      /// We create the token
      const isReminder = message.includes(
        "{https://myreded.com/event-confirmation/token}"
      );
      const token = await handleTokenCreation("sms", uid, isReminder);
      const link =
        process.env.NODE_ENV === "development"
          ? `http://localhost:3000/event-confirmation/${token}/${authUser.calendarId}`
          : `https://myreded.com//event-confirmation/${token}/${authUser.calendarId}`;
      let finalMessage = message.replace(
        "{https://myreded.com/event-confirmation/token}",
        link
      );
      sendTextMessage(to, finalMessage)
        .then((res) => {
          if (!res.success) {
            toast.error(`There was an error sending this SMS`);
          }
          if (!fromBulk && res.success) {
            toast.success(`SMS sent succesfully`);
          }
        })
        .catch((err) => {
          toast.error(`There was an error sending this SMS`);
        });
      setReminderModalOpen(false);
    }
  };

  const handleBulkSend = async (
    mailSubject,
    mailChecked,
    mailMessage,
    smsChecked,
    smsMessage
  ) => {
    const toastId = toast.loading("Sending Reminders...");

    const promises = users.map(async (user) => {
      if (smsChecked) {
        await handleSendOneSMS(user.phoneNumber, smsMessage, user.uid, true);
      }
      if (mailChecked) {
        await handleSendOneMail(
          user.email,
          mailSubject,
          mailMessage,
          user.displayName,
          user.uid,
          true
        );
      }
    });

    await Promise.all(promises);

    toast.dismiss(toastId);
    toast.success("Reminders sent");
  };

  const createObjectfromExistingEvent = async () => {
    try {
      setLoadingEvent(true);
      const googleEvent = await getCalendarEvent(
        eventData.googleCalendarId,
        calendarId
      );

      console.log("googleEvent", googleEvent);
      const usersList = await getUsersWithClassId(authUser.classId);
      console.log(usersList, "usersList");
      let attendeesObject = {};
      console.log("usersList", usersList);
      usersList.forEach((element) => {
        attendeesObject[element.uid] = {
          attendance: false,
          reminderConfirmed: {
            // uid : {type: phone/mail, confirmed: true/false, token:id}
          },
        };
      });

      const event = {
        title: googleEvent.summary,
        ...(googleEvent.description && {
          description: googleEvent.description,
        }),
        ...(googleEvent.location && { location: googleEvent.location }),
        createdAt: googleEvent.created,
        creator: googleEvent.creator,
        attendees: attendeesObject,
        googleCalendarId: googleEvent.id,
        classId: authUser.classId,
        startDate: googleEvent.start?.dateTime,
        endDate: googleEvent.end?.dateTime,
      };

      console.log("AttendancePage.js 322 | event to push to events", event);

      // Push the event object to the database
      await firebase.events().push(event);
      console.log(event, "event");
      setHasNoEventObject(false);
      fetchEventData(classroom.calendarId);
    } catch (error) {
      console.log("EventManager 325 | error creating event", error);
      setLoadingEvent(false);
    }
  };

  const handleCellClick = (cell) => {
    let attended;
    // we see if the user is already "attended" so we toggle the property from true to falseor viceversa
    eventData.attendeesList.forEach((e) => {
      if (e.uid === cell.row.original.uid) {
        attended = e.attendance;
      }
    });

    // if the admin click attendance column we update the users attendance property
    if (cell.column.Header === "Attendance" && attendanceMode) {
      let path = `/attendees/${cell.row.original.uid}/attendance`;
      console.log("click on cel", cell);
      firebase
        .getEventData(eventData.eventId)
        .update({
          [path]: !attended,
        })
        .then((res) => fetchEventData(classroom.calendarId));
    }

    // if the admin click reminder column we update the open the reminder modal
    if (cell.column.Header === "Reminder" && !isPastEvent) {
      setReminderModalOpen({
        type: "personal",
        title: cell.row.cells[0].value,
        uid: cell.row.original.uid,
        mail: matchUserProperty(users, cell.row.original.uid, "email"),
        phone: matchUserProperty(users, cell.row.original.uid, "phoneNumber"),
      });
    }
  };

  const getUsersWithClassId = async (classId) => {
    // 3 we load the users from the class, which we need since the event only stores the uid and we need the names/phone/mails
    let usersList;
    let filteredUsersList;
    await firebase
      .users()
      .orderByChild("classId")
      .equalTo(classId)
      .once("value", (snap) => {
        const usersObject = snap.val();
        if (usersObject) {
          usersList = Object.keys(usersObject).map((key) => ({
            ...usersObject[key],
            uid: key,
          }));
          console.log("ViewEvent users", usersList);
          setLoadingUsers(false);
          // Here we filter users that have displayname, users that are neither mentors nor admins, and users that have left the cohort
          filteredUsersList = usersList.filter(
            (e) => e.displayName && !e.isMentor && !e.isAdmin && !e.inactive
          );
          setUsersList(filteredUsersList);
        }
      });
    return usersList;
  };

  const fetchEventData = async (googleCalendarId) => {
    console.log("eventData", eventData);
    // 2 we fetch the event data by the google calendar property, then we set the state, and start the loading for users
    const googleEvent = await getCalendarEvent(googleCalendarId, calendarId);
    console.log("googleEvent", googleEvent);
    await firebase
      .events()
      .orderByChild("googleCalendarId")
      .equalTo(calendarId)
      .once("value", (snapshot) => {
        let responseData = snapshot.val();
        if (responseData === null) {
          setHasNoEventObject(true);
          setLoadingEvent(false);
          return;
        }
        let eventData = Object.values(snapshot.val())[0];
        let attendeesList = eventData.attendees
          ? Object.keys(eventData.attendees).map((key) => ({
              attendance: eventData.attendees[key].attendance,
              reminderConfirmed: eventData.attendees[key].reminderConfirmed,
              uid: key,
            }))
          : [];
        console.log("eventdata", eventData);
        setLoadingEvent(false);
        setLoadingUsers(true);
        getUsersWithClassId(eventData.classId);
        setEventData({
          eventName: eventData.title,
          startDate: googleEvent.start.dateTime,
          endDate: googleEvent.end.dateTime,
          description: eventData.description,
          location: eventData.location,
          classId: eventData.classId,
          eventId: Object.keys(responseData)[0],
          module: eventData.module,
          attendeesList: attendeesList,
        });

        fetchModule(eventData.module);
      });
  };

  const fetchModule = async (moduleId) => {
    console.log("getting module");
    firebase.getLesson(moduleId).once("value", (snap) => {
      const module = snap.val();
      if (module) {
        setModuleName(module.name);
      }
      //     setLoadingUsers(false);
    });
  };

  useEffect(() => {
    // 1) we set the loading state and fetch the event data
    setLoadingEvent(true);
  }, []);

  return (
    <div className="event__manager__container">
      {reminderModalOpen.type && (
        <EventReminderModal
          title={reminderModalOpen.title}
          type={reminderModalOpen.type}
          mail={reminderModalOpen.mail}
          phone={reminderModalOpen.phone}
          closeModal={setReminderModalOpen}
          handleSendOneMail={handleSendOneMail}
          handleSendOneSMS={handleSendOneSMS}
          handleBulkSend={handleBulkSend}
        />
      )}
      {editEventOpen && (
        <EditEventModal
          createPrivateEventOpen={editEventOpen}
          setCreatePrivateEventOpen={setEditEventOpen}
          eventName={eventData.eventName}
          eventAddress={eventData.eventAddress}
          eventDescription={eventData.description}
          eventLocation={eventData.location}
          eventStartDate={eventData.startDate}
          eventEndDate={eventData.endDate}
          eventId={eventData.eventId}
          eventModule={eventData.module}
          googleCalendarEventId={calendarId}
          calendarId={authUser.calendarId}
          classId={eventData.classId}
          fetchEventData={fetchEventData}
          classroom={classroom}
        />
      )}

      {testReminderOpen && (
        <TestEventReminderModal
          closeModal={setTestReminderOpen}
          authUser={authUser}
          eventId={eventData.eventId}
        />
      )}

      {loadingEvent ? (
        <div>loading..</div>
      ) : hasNoEventObject ? (
        <div style={{ margin: "auto" }}>
          <div>
            This event has no manager associated to it, do you want to create
            one?
          </div>
          <button
            onClick={createObjectfromExistingEvent}
            className="mentorprivateeventbuttons__button"
          >
            {!loadingEvent ? "Create" : "Creating..."}
          </button>
        </div>
      ) : (
        <>
          <EventHeader
            eventData={eventData}
            isPastEvent={isPastEvent}
            setReminderModalOpen={setReminderModalOpen}
            setTestReminderOpen={setTestReminderOpen}
            setEditEventOpen={setEditEventOpen}
          />
          <EventDetails
            eventData={eventData}
            moduleName={moduleName}
            setEditEventOpen={setEditEventOpen}
          />
          <div className="event__labels">
            <div>
              <span>Email</span>
              <div className="blue__ball__legend" />
            </div>
            <div>
              <span>Text</span>
              <span className="orange__ball__legend" />
            </div>
            <div>
              <span>Push</span>
              <span className="red__ball__legend" />
            </div>
            <div>
              <span>Other</span>
              <span className="yellow__ball__legend" />
            </div>
            <div>
              <span>Confirmed</span>
              <span>
                <img
                  src={"/assets/images/green_check_icon.svg"}
                  className="evaluation_icons_glosary"
                  alt="green check icon"
                />
              </span>
            </div>
            <div>
              <span>Rejected</span>
              <span>
                <img
                  src={"/assets/images/red_cross_icon.svg"}
                  className="evaluation_icons_glosary"
                  alt="red cross icon"
                />
              </span>
            </div>
          </div>
          <div className="attendance__page__attendance__mode">
            Attendance mode{" "}
            <ToggleSwitch isActive={attendanceMode} func={setAttendanceMode} />
          </div>
          <AttendanceTable
            eventData={eventData}
            users={users}
            handleCellClick={handleCellClick}
            attendanceMode={attendanceMode}
            totalRemindersConfirmed={totalRemindersConfirmed}
            totalUsersAttendance={totalUsersAttendance}
          />
        </>
      )}
      {classroom && (
        <ClassroomChat
          authUser={authUser}
          firebase={firebase}
          currentClassroomData={classroom}
          classId={classroom.uid}
        />
      )}
    </div>
  );
};

const condition = (authUser) =>
  authUser && (authUser.roles[ROLES.ADMIN] || authUser.isMentor);

export default compose(
  withAuthorization(condition),
  withAuthentication,
  withFirebase
)(AttendancePage);
